import React from 'react';
import './BlogCard.css';

function BlogCard({ title, author, imageUrl, excerpt, link }) {
  return (
    <li className="blog-card">
      <div className="post-overlay">
        <figure className="post-featured-image">
          <a href={link} tabIndex="-1">
            <img src={imageUrl} alt={title} className="img-fluid" />
            <span className="post-image-overlay"></span>
          </a>
        </figure>
        <div className="post-content">
          <h2 className="post-title">
            <a href={link}>{title}</a>
          </h2>
          <p className="post-author">by {author}</p>
          <p className="post-excerpt">{excerpt}</p>
          <a href={link} className="read-more">
            Continue reading
            <svg className="continue-reading-arrow" width="24" height="13" fill="none">
              <path d="M.922 6.375h21.077M15.41.717l6.587 5.658-6.587 5.658" stroke="#EF4953" strokeWidth="1.5"></path>
            </svg>
          </a>
        </div>
      </div>
    </li>
  );
}

export default BlogCard;
