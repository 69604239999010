import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';
import './NewBlogPostPage.css';
import doorsBlogLogo from '../../images/DoorsBlog1.png';

function NewBlogPostPage() {
  const [formData, setFormData] = useState({
    title: '',
    author: '',
    imageUrl: '',
    content: '',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('http://127.0.0.1:8000/api/blog-posts/create/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.url) {
          alert(`Blog post created successfully! View it here: ${data.url}`);
        } else {
          alert('Blog post created successfully!');
        }
        setFormData({
          title: '',
          author: '',
          imageUrl: '',
          content: '',
        });
      })
      .catch((error) => {
        console.error('Error creating blog post:', error);
        alert('Failed to create blog post.');
      });
  };

  const navigateToBlogPosts = () => {
    navigate('/blog');
  };

  return (
    <div>
      <NavBar />
      <div className="blog-page">
        <img 
          src={doorsBlogLogo} 
          alt="Coding Doors Logo" 
          className="blog-logo" 
        />
        <h2 className="blog-title">Create New Post</h2>
        <form className="blog-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Author</label>
            <input
              type="text"
              name="author"
              value={formData.author}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Image URL</label>
            <input
              type="url"
              name="imageUrl"
              value={formData.imageUrl}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Content</label>
            <textarea
              name="content"
              value={formData.content}
              onChange={handleChange}
              rows="5"
              required
            ></textarea>
          </div>
          <div className="form-actions">
            <button type="submit" className="blog-submit-btn">
              Submit
            </button>
            <button 
              type="button" 
              onClick={navigateToBlogPosts} 
              className="blog-view-btn"
            >
              See Blog Posts
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewBlogPostPage;
