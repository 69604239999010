import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useEffect } from 'react';
import { initMixpanel, identifyMixpanel } from './mixpanelWrapper';
import { v4 as uuidv4 } from 'uuid';
import { Provider, ErrorBoundary } from '@rollbar/react'; // Provider imports 'rollbar'
import { AuthProvider } from './AuthContext';

import ProficiencyTestIntroduction from "./screens/ProficiencyTestScreens/ProficiencyTestIntroduction";
//import scheduleMeetingLogo from './images/scheduleMeetingLogo.png';
//import popupTutoringBackground from './images/popupTutoringBackground.png';

import QuestionScreen from './screens/QuestionScreen/QuestionScreen';
import PythonTreePage from './screens/TreePage/PythonTreePage';
//import CTreePage from './screens/TreePage/CTreePage';
import WeekDetailsScreen from './screens/WeekDetailsScreen/WeekDetailsScreen';
import HomeScreen from './screens/HomeScreen/HomeScreen';
// import QuizIntroduction from "./screens/QuizScreens/QuizIntroduction";
import ContributeScreen from "./screens/ContributeScreen/ContributeScreen";
import LandingPage from "./screens/LandingPage/LandingPage";
import ThankYouPage from "./screens/LandingPage/ThankYouPage";
import ThankYouBootcampPage from "./screens/BootcampPage/ThankYouBootcampPage";
//import WhatDoYouWantToLearn from "./screens/WhatDoYouWantToLearn/WhatDoYouWantToLearn";
// import QuizResults from "./screens/QuizScreens/QuizResults";
import SalesPage from "./screens/SalesPage/SalesPage";
import TidyCalEmbed from "./screens/CalendarPage/TidyCalEmbed";
import TidyCalEmbedAutomations from "./screens/CalendarPage/TidyCalEmbedAutomations";
import CodeSolutions from "./screens/CodeSolutions/CodeSolutions";
import InnerCodeSolutions from "./screens/CodeSolutions/InnerCodeSolutions";
// import QuizQuestion from "./screens/QuizScreens/QuizQuestion";
import BootcampPage from "./screens/BootcampPage/BootcampPage";
//import BootcampPageWaitlist from "./screens/BootcampPage/BootcampPageWaitlist";
import ContactPage from "./screens/ContactPage/ContactPage";
import TestimonyPage from "./screens/TestimonyPage/TestimonyPage";
import CodeHelpPage from "./screens/CodeHelpPage/CodeHelpPage";
import AIBootcampPage from "./screens/AIBootcampPage/AIBootcampPage";
import BlogPage from "./screens/BlogPage/BlogPage";
import BlogPost from "./screens/BlogPage/BlogPost";
import NewBlogPostPage from "./screens/BlogPage/NewBlogPostPage";

import AutomationSalesPage from "./screens/AutomationsSalesPage/AutomationsSalesPage";
import AIBootcampPageWaitlist from "./screens/AIBootcampPage/AIBootcampPageWaitlist";


const rollbarConfig = {
  accessToken: 'dc19cdae5a0a42ad96a15054e66a1b78',
  environment: 'testenv',
};

function TestError() {
  const a = null;
  return a.hello();
}

// Replace YOUR_TOKEN with your Project Token
initMixpanel('c73e54dcf9a9a4ed209d91833a97b38d', {debug: true});

function App() {
  
  /*const [showPopup, setShowPopup] = useState(false);
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    setStartTime(Date.now());
    const interval = setInterval(() => {
      if (Date.now() - startTime >= 120000) {
        setShowPopup(true);
        clearInterval(interval); // Clear the interval when the popup is shown
      }
    }, 1000); // Check every second if 2 minutes have passed
  
    return () => clearInterval(interval); // Clear the interval when the component is unmounted
  }, [startTime]);*/

  /*const handleClick = () => {
    trackMixpanel('ScheduleMeeting');
  };*/

  useEffect(() => {
    // For testing purposes, use a hardcoded email address
    // const testEmail = 'rodrigomlp@gmail.com';
    // mixpanel.identify(testEmail);
    // Uncomment the following lines when you're done testing
    const getOrCreateUserId = () => {
      let userId = localStorage.getItem('userId');
      if (!userId) {
        userId = uuidv4();
        localStorage.setItem('userId', userId);
      }
      return userId;
    };
    
    const userId = getOrCreateUserId();
    identifyMixpanel(userId);

    // Other app initialization logic, if needed
  }, []);
  return (
    <Provider config={rollbarConfig}>
     <ErrorBoundary>
      <AuthProvider>
        <BrowserRouter>
          {/*showPopup && (
            <div className="popup-background" onClick={() => setShowPopup(false)}>
              <div className="popup-tutoring" onClick={(e) => e.stopPropagation()}>
                <img src={popupTutoringBackground} alt="" className="popup-tutoring-background-img" />
                <button className="close-button-tutoring" onClick={() => setShowPopup(false)}><p>X</p></button>
                <div className="popup-text">
                  <h1>Ready to learn <strong>code</strong></h1>
                  <h1>with a <strong>private tutor</strong>?</h1>
                  <br/>
                  <h6>Unleash Your Coding Potential,</h6>
                  <h6>Personalized Programming Tutoring</h6>
                  <h6>with Flexible Scheduling.</h6>
                </div>
                <button className='popup-tutoring-btn' onClick={handleClick}>
                  <a href='https://www.dorscodingschool.com/coachingplans'>
                    <img src={scheduleMeetingLogo} alt='' />
                    <p className='popup-tutoring-text'>Schedule Meeting</p>
                  </a>
                </button>
              </div>
            </div>
          )*/}
          <Routes>
            <Route path="/problem/:id" element={<QuestionScreen />} />
            <Route path="/python" element={<PythonTreePage />} />
            <Route path="/week/:number" element={<WeekDetailsScreen />} />
            <Route path="/" element={<HomeScreen />} />
            <Route path="/landingpage" element={<LandingPage />} />
            <Route path="/thankyou" element={<ThankYouPage />} />
            <Route path="/proficiency/:week" element={<ProficiencyTestIntroduction />} />
            <Route path="/test-error" element={<TestError />} />
            {/* <Route path="/quiz" element={<QuizIntroduction />} />
            <Route path="/quiz/:questionNumber" element={<QuizQuestion />} />
            <Route path="/quiz-results" element={<QuizResults />} /> */}
            <Route path="/contribute" element={<ContributeScreen />} />
            <Route path="/codehelp" element={<CodeHelpPage />} />
            <Route path="/coachingplans" element={<SalesPage />} /> 
            <Route path="/schedulemeeting" element={<TidyCalEmbed />} />
            <Route path="/schedulemeeting-automations" element={<TidyCalEmbedAutomations />} />
            <Route path="/codesolutions" element={<CodeSolutions />} />
            <Route path="/solutions/:course" element={<InnerCodeSolutions />} />
            <Route path="/bootcamp" element={<BootcampPage />} />
            <Route path="/thankyoubootcamp" element={<ThankYouBootcampPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/testimonials" element={<TestimonyPage />} />
            <Route path="/bootcamp-ai" element={<AIBootcampPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/:postid" element={<BlogPost />} />
            <Route path="/blog/new" element={<NewBlogPostPage />} />
            <Route path="/automations" element={<AutomationSalesPage />} />
            <Route path="/waitlist" element={<AIBootcampPageWaitlist />} />
            {/*<Route path="/clanguage" element={<CTreePage />} />
            <Route path="/learn" element={<WhatDoYouWantToLearn />} />*/}
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ErrorBoundary>
    </Provider>
  );
}

export default App;
