import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar/NavBar";
import doorsBlogLogo from "../../images/DoorsBlog1.png";
import "./BlogPost.css";

function BlogPost() {
  const { postid } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const [tableOfContents, setTableOfContents] = useState([]);
  const [postIds, setPostIds] = useState([]); // Store all valid post IDs

  useEffect(() => {
    // Fetch all post IDs
    fetch("http://127.0.0.1:8000/api/blog-post-ids/")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch post IDs");
        }
        return response.json();
      })
      .then((ids) => setPostIds(ids))
      .catch((err) => console.error("Error fetching post IDs:", err));
  }, []);

  useEffect(() => {
    // Fetch current post details
    fetch(`http://127.0.0.1:8000/api/blog-posts/${postid}/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch the blog post");
        }
        return response.json();
      })
      .then((data) => {
        setPost(data);
        const toc = generateTableOfContents(data.content);
        setTableOfContents(toc);
      })
      .catch((err) => setError(err.message));
  }, [postid]);

  const generateTableOfContents = (content) => {
    const lines = content.split("\n");
    const toc = [];
    let lastMainTopicIndex = null;

    lines.forEach((line, index) => {
      if (line.startsWith("-")) {
        lastMainTopicIndex = toc.length;
        toc.push({
          id: `section-${index}`,
          text: line.replace("-", "").trim(),
          subtopics: [],
          isExpanded: false,
        });
      } else if (line.startsWith("*") && lastMainTopicIndex !== null) {
        toc[lastMainTopicIndex].subtopics.push({
          id: `subsection-${index}`,
          text: line.replace("*", "").trim(),
        });
      }
    });

    return toc;
  };

  const toggleSubtopics = (index) => {
    setTableOfContents((prevToc) =>
      prevToc.map((item, i) =>
        i === index ? { ...item, isExpanded: !item.isExpanded } : item
      )
    );
  };

  const navigateToPost = (direction) => {
    const currentIndex = postIds.indexOf(parseInt(postid));
    if (currentIndex !== -1) {
      const newPostId =
        direction === "next"
          ? postIds[currentIndex + 1]
          : postIds[currentIndex - 1];
      if (newPostId) {
        navigate(`/blog/${newPostId}`);
      }
    }
  };

  const renderContent = (content) => {
    const lines = content.split("\n");
    return lines.map((line, index) => {
      if (line.startsWith("-")) {
        return (
          <h2
            id={`section-${index}`}
            key={index}
            className="blog-post-section-title"
          >
            {line.replace("-", "").trim()}
          </h2>
        );
      }
      if (line.startsWith("*")) {
        return (
          <p id={`subsection-${index}`} key={index}>
            {line.replace("*", "").trim()}
          </p>
        );
      }
      if (line.startsWith("![") && line.includes("](")) {
        const altText = line.match(/\[.*?\]\(.*?\)/)?.[1] || "Image";
        const imageUrl = line.match(/\((.*?)\)/)?.[1];
        if (imageUrl) {
          return (
            <img
              src={imageUrl}
              alt={altText}
              key={index}
              className="blog-post-image"
            />
          );
        }
      }
      return <p key={index}>{line}</p>;
    });
  };

  if (error) {
    return (
      <div className="blog-post-page">
        <p className="error-message">{error}</p>
      </div>
    );
  }

  if (!post || postIds.length === 0) {
    return (
      <div className="blog-post-page">
        <p>Loading...</p>
      </div>
    );
  }

  const currentIndex = postIds.indexOf(parseInt(postid));

  return (
    <div>
      <NavBar />
      <div className="blog-post-page">
        <div className="blog-logo">
          <img src={doorsBlogLogo} alt="Doors Blog Logo" />
        </div>

        <header className="blog-post-header">
          <img
            src={post.imageUrl}
            alt={post.title}
            className="blog-post-main-image"
          />
          <div className="blog-post-header-content">
            <h1 className="blog-post-title">{post.title}</h1>
            <p className="blog-post-author">
              <em>by {post.author}</em>
            </p>
          </div>
        </header>

        {tableOfContents.length > 0 && (
          <div className="blog-post-table-of-contents">
            <h2>Table of Contents</h2>
            <ul>
              {tableOfContents.map((item, index) => (
                <li
                  key={item.id}
                  className={`toc-item ${
                    index === tableOfContents.length - 1 ? "toc-last-item" : ""
                  }`}
                >
                  <div className="toc-main-topic">
                    <svg
                      className="toc-arrow"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M12 3l6 9-6 9v-6H3v-6h9V3z" />
                    </svg>
                    <a href={`#${item.id}`}>{item.text}</a>
                    {item.subtopics.length > 0 && (
                      <span
                        className="toc-expand-icon"
                        onClick={() => toggleSubtopics(index)}
                      >
                        {item.isExpanded ? "-" : "+"}
                      </span>
                    )}
                  </div>
                  <ul
                    className={`blog-post-subtopics ${
                      item.isExpanded ? "expanded" : ""
                    }`}
                  >
                    {item.subtopics.map((subitem) => (
                      <li key={subitem.id}>
                        <a href={`#${subitem.id}`}>{subitem.text}</a>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="blog-post-content">{renderContent(post.content)}</div>

        {/* Backward Button */}
        <button
          className={`back-post-btn ${currentIndex <= 0 ? "hidden" : ""}`}
          onClick={() => navigateToPost("back")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            width="24px"
            height="24px"
          >
            <path d="M15 19l-7-7 7-7" />
          </svg>
        </button>

        {/* Forward Button */}
        <button
          className={`next-post-btn ${
            currentIndex === postIds.length - 1 ? "hidden" : ""
          }`}
          onClick={() => navigateToPost("next")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            width="24px"
            height="24px"
          >
            <path d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default BlogPost;
