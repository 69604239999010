import React, { useState, useEffect } from "react";
import BlogCard from "./BlogCard";
import NavBar from "../../components/NavBar/NavBar";
import "./BlogPage.css";
import doorsBlogLogo from "../../images/DoorsBlog1.png";

function BlogPage() {
  const [blogPosts, setBlogPosts] = useState([]);

  // Fetch blog posts
  useEffect(() => {
    fetch("http://127.0.0.1:8000/api/blog-posts/")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch blog posts");
        }
        return response.json();
      })
      .then((data) => {
        const processedPosts = data.map((post) => ({
          ...post,
          excerpt: post.excerpt
            .split("\n")
            .filter((line) => !line.trim().startsWith("-"))
            .map((line) => line.replace(/!\[.*?\]\(.*?\)/g, "").trim())
            .map((line) => line.replace(/^\*/, "").trim())
            .join(" ")
            .trim(),
        }));
        setBlogPosts(processedPosts);
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error);
      });
  }, []);

  const navigateToCreateBlog = () => {
    window.location.href = "/blog/new";
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <NavBar />
      <div className="blog-page">
        <button className="new-blog-btn" onClick={navigateToCreateBlog}>
          + New Blog Post
        </button>
        <img src={doorsBlogLogo} alt="Coding Dors Logo" className="blog-logo" />
        <div className="blog-list">
          {blogPosts.map((post) => (
            <BlogCard
              key={post.id}
              title={post.title}
              author={post.author}
              imageUrl={post.imageUrl}
              excerpt={post.excerpt}
              link={`/blog/${post.id}`}
            />
          ))}
        </div>
        <button className="scroll-bottom-btn" onClick={scrollToBottom}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            width="32px"
            height="32px"
          >
            <path d="M12 10l7-7H5l7 7zm0 10l7-7H5l7 7z" />
          </svg>
        </button>
        <button className="scroll-top-btn" onClick={scrollToTop}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            width="32px"
            height="32px"
          >
            <path d="M12 14l7 7H5l7-7zm0-10l7 7H5l7-7z" />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default BlogPage;
